export function camelToSnakeCase(str: string) {
  return str.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
}

export function capitalizeFirstWord(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeAllWords(str: string): string {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function base64Encode(str: string): string {
  if (typeof window !== "undefined") {
    return window.btoa(str);
  } else if (typeof Buffer !== "undefined") {
    return Buffer.from(str).toString("base64");
  }
  throw new Error("Base64 encoding is not supported in this environment");
}

export function base64Decode(str: string): string {
  if (typeof window !== "undefined") {
    return window.atob(str);
  } else if (typeof Buffer !== "undefined") {
    return Buffer.from(str, "base64").toString();
  }
  throw new Error("Base64 decoding is not supported in this environment");
}
